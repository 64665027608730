// Variables
@import "1-variables/app";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Theme mixins
@import "2-mixins/background";
@import "2-mixins/badge";
@import "2-mixins/button";
@import "2-mixins/modal";
@import "2-mixins/switch";
@import "2-mixins/tabs";
@import "2-mixins/theme";
@import "2-mixins/wizard";

// Theme components
@import "3-components/accordion";
@import "3-components/alert";
@import "3-components/avatar";
@import "3-components/badge";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/chart";
@import "3-components/chat";
@import "3-components/content";
@import "3-components/dropdown";
@import "3-components/feather";
@import "3-components/footer";
@import "3-components/forms";
@import "3-components/hamburger";
@import "3-components/illustrations";
@import "3-components/landing";
@import "3-components/main";
@import "3-components/modal";
@import "3-components/navbar";
@import "3-components/progress";
@import "3-components/reboot";
@import "3-components/root";
@import "3-components/settings";
@import "3-components/sidebar";
@import "3-components/sizing";
@import "3-components/stats";
@import "3-components/tables";
@import "3-components/tabs";
@import "3-components/timeline";
@import "3-components/type";
@import "3-components/wizard";
@import "3-components/wrapper";

// Theme utilities
@import "4-utilities/background";
@import "4-utilities/borders";
@import "4-utilities/cursors";
@import "4-utilities/font";
@import "4-utilities/overflow";
@import "4-utilities/position";
@import "4-utilities/z-index";

// 3rd party plugins
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~apexcharts/src/assets/apexcharts";
@import "~bootstrap-daterangepicker/daterangepicker";
@import "~datatables.net-bs4/css/dataTables.bootstrap4";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4";
@import "~dragula/dist/dragula";
@import "~fullcalendar/main";
@import "~jsvectormap/dist/css/jsvectormap";
@import "~notyf/notyf.min";
@import "~quill/dist/quill.bubble";
@import "~quill/dist/quill.snow";
@import "~select2/src/scss/core";
@import "~simplebar/dist/simplebar";
@import "~smartwizard/dist/css/smart_wizard_all";
@import "~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4";

// 3rd party plugin styles
@import "5-vendor/datatables-select";
@import "5-vendor/daterangepicker";
@import "5-vendor/datetimepicker";
@import "5-vendor/fullcalendar";
@import "5-vendor/highlight";
@import "5-vendor/jsvectormap";
@import "5-vendor/quill";
@import "5-vendor/select2";
@import "5-vendor/simplebar";
@import "5-vendor/smartwizard";

// adds
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
        .border#{$infix}-end {    border-right: $border-width solid $border-color !important; }
        .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
        .border#{$infix}-start {     border-left: $border-width solid $border-color !important; }

        .border#{$infix}-top-0 {    border-top: 0 !important; }
        .border#{$infix}-end-0 {  border-right: 0 !important; }
        .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
        .border#{$infix}-start-0 {   border-left: 0 !important; }
    }
}

.feather-8 {
    width: 8px;
    height: 8px;
    position: relative;
    top: -1px;
}

.feather-10 {
    width: 10px;
    height: 10px;
    position: relative;
    top: -1px;
}

.feather-12 {
    width: 12px;
    height: 12px;
    position: relative;
    top: -2px;
}

.feather-14 {
    width: 14px;
    height: 14px;
    position: relative;
    top: -2px;
}

.feather-16 {
    width: 16px;
    height: 16px;
    position: relative;
    top: -2px;
}

.feather-24 {
    width: 24px;
    height: 24px;
    position: relative;
    top: -2px;
}
.feather-32 {
    width: 32px;
    height: 32px;
    position: relative;
    top: -3px;
}

.qr-code svg {
    width: 90%;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


